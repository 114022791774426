import React from "react"
import SEO from "../../components/seo"

import { useStaticQuery, graphql, navigate } from "gatsby"
import { Container } from "./about.styles"
import { Element } from "react-scroll"
import CustomButton from "../../components/custom-button/custom-button.component"
import Card from "../../components/card/card.component"
const About = () => {
  const {
    mdx,
    allMdx: { nodes },
  } = useStaticQuery(graphql`
    query AboutQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/staff-members/" } }
        sort: { fields: frontmatter___id }
      ) {
        nodes {
          frontmatter {
            id
            title
            featuredimage {
              alt
              src {
                publicURL
              }
            }
          }
          body
        }
      }
      mdx(frontmatter: { title: { eq: "About Green Yoga" } }) {
        id
        body
        frontmatter {
          id
          title
          buttontext
          featuredimage {
            src {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  `)

  /*   const [showStory, setShowStory] = useState(true)
  const [showCharity, setShowCharity] = useState(false)

  const handleClickStory = () => {
    setShowStory(false)
    setShowCharity(true)
    scroller.scrollTo("about", {
      duration: 0,
      delay: 0,
      smooth: true,
      offset: -550,
    })
  } */
  console.log(nodes)
  nodes.sort((a, b) => a.frontmatter.id - b.frontmatter.id)
  console.log(nodes)
  let founders = nodes.slice(0, 2)

  //let staff = nodes.slice(2, nodes.length)
  //removed staff={staff} from card

  return (
    <>
      <SEO title="About Green Yoga | Online Yoga Classes" />
      <Element name="about"></Element>
      <Container>
        {mdx && (
          <Card data={mdx} founders={founders} > 
            <CustomButton onClick={() => navigate("/about/plantation")}>
              {mdx.frontmatter.buttontext}
            </CustomButton>
          </Card>
        )}
      </Container>
    </>
  )
}

export default About
