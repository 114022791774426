import React from "react"

import { CardContainer } from "./card.styles"
import { MDXRenderer } from "gatsby-plugin-mdx"

//erased staff parameterfor now; const Card = ({ data, children, founders, staff, ...props }) and staff={staff} from mdxrenderer below

const Card = ({ data, children, founders, ...props }) => {
  return (
    <CardContainer {...props}>
      {data && (
        <MDXRenderer data={data} founders={founders}>
          {data.body}
        </MDXRenderer>
      )}
      {children}
    </CardContainer>
  )
}

export default React.memo(Card)
